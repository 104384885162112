import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, toast, ToastContainer } from 'mdbreact'
import { Zoom } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { Link } from 'gatsby'

const validationSchema = Yup.object().shape({    
  email: Yup.string()
    .email('Feld wurde nicht im erwarteten Format ausgefüllt')
    .required('Bitte E-Mail-Adresse angeben'),
  consentsubscribe: Yup.boolean().oneOf([true], 'Ihre Zustimmung zu den allgemeinen Geschäftsbedingungen ist erforderlich'),
  })

const formUrl = 'https://script.google.com/macros/s/AKfycbz_G-fsc11JMc_MNqHngxmKYenLIK1pU5ftmiKjn5z8KJsjpJchNgrgGKFGXVIQAJTrKA/exec'

const messageConfirm = () => {
  return toast.info("Danke, dass Sie uns kontaktiert haben. Wir werden uns in Kürze bei Ihnen melden!", { closeButton: false, position: toast.POSITION.TOP_CENTER, transition: Zoom })
}

class eNewsletter extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      pageUrl: null,
      load: false,
      expired: 'false',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ 
        load: true,
        pageUrl: location.href 
      })
    })
  }

  handleChange = value => {
    this.setState({ value })
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }

  render() {
    const { value, callback, load, expired } = this.state || {}

    return (
      <section className="mt-n5">
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol md="6" className="md-0 mb-5">
            <Formik
              initialValues={{
                firstname: '',
                lastname: '',
                country: '',
                email: '',
                consentsubscribe: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, {setSubmitting, resetForm}) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                  Firstname: values.firstname,
                  Lastname: values.lastname,
                  Country: values.country,                  
                  Email: values.email,
                  Consentsubscribe: values.consentsubscribe,
                }
                try {
                  const response = axios({
                      method: 'get',
                      mode: 'no-cors',
                      url: `${formUrl}?timestamp=&url=${encodeURIComponent(this.state.pageUrl)}&firstname=${encodeURIComponent(values.firstname)}&surname=${encodeURIComponent(values.lastname)}&country=${encodeURIComponent(values.country)}&email=${encodeURIComponent(values.email)}&consent=${encodeURIComponent(values.consentsubscribe)}`
                    })
                      this.setState({values: '', expired: 'true'})
                      resetForm()
                      messageConfirm()
                    } catch (e) {
                      console.log(`Axios request failed: ${e}`)
                    }
                  setSubmitting(false)
                }}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form
                  name="contact"
                  method="post"
                  action=""
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />
                  <MDBRow>
                    <MDBCol lg="6" md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="firstname">
                          Vorname
                        </label>
                        <Field
                          type="firstname"
                          name="firstname"
                          aria-label="firstname"
                          value={values.firstname}
                          placeholder="Vorname"
                          className={`form-control ${
                            touched.firstname && errors.firstname ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="firstname"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>

                    <MDBCol lg="6" md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="lastname">
                            Nachname
                        </label>
                        <Field
                          type="lastname"
                          name="lastname"
                          aria-label="lastname"
                          value={values.lastname}
                          placeholder="Nachname"
                          className={`form-control ${
                            touched.lastname && errors.lastname ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="lastname"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>                    
                  </MDBRow>

                  <MDBRow>
                    <MDBCol lg="6" md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="country">
                          Land
                        </label>
                        <Field
                          type="text"
                          name="country"
                          aria-label="country"
                          value={values.country}
                          placeholder="Land"
                          className={`form-control ${
                            touched.country && errors.country
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="country"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol lg="6" md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="email">
                          E-Mail <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="email"
                          name="email"
                          aria-label="email"
                          value={values.email}
                          placeholder="E-Mail-Adresse"
                          className={`form-control ${
                            touched.email && errors.email ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="email"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mt-5 mb-4 grey-text font-w-400 text-small">
                        Mit Ankreuzen der Checkbox und Anklicken des Buttons „Absenden“ willigen Sie ein, dass die Macro 4 GmbH Ihre im Formular angegebene E-Mailadresse verarbeiten darf, um im Rahmen eines Newsletter über Produkte, Dienstleistungen, Neuigkeiten und Veranstaltungen der Macro 4 GmbH zu informieren. Die Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit. a) DSGVO. Ihre hier erteilte Einwilligung ist freiwillig und kann jederzeit und kostenlos für die Zukunft widerrufen werden. Die ausführliche Datenschutzerklärung inklusive Ihrer Rechte und Widerrufsmöglichkeiten finden Sie unter nachfolgendem Link.
                        <br /><br />
                        <Link to="/privacy-statement/" className="effect">Datenschutzerklärung</Link>
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mt-3 mb-2 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="consentsubscribe"
                          name="consentsubscribe"
                          aria-label="consentsubscribe"
                          className={`form-check-input ${
                            touched.consentsubscribe && errors.consentsubscribe
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <label htmlFor="consentsubscribe">
                          Ja, ich bin einverstanden. <span style={{ color: 'red' }}>*</span>
                        </label>
                        <ErrorMessage name="consentsubscribe" component="div" className="invalid-feedback font-w-700 text-medium-small" />
                      </div>
                    </MDBCol>
                  </MDBRow>               

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '120px' }}>
                        <button
                          type="submit"
                          className="mt-4 btn btn-medium btn-unicom"
                          style={{ display: 'block' }}
                          disabled={ isSubmitting ? true : false }
                        >
                          {isSubmitting ? 'Please wait...' : 'Absenden'}
                        </button>
                        <ToastContainer
                          transition={Zoom}
                          hideProgressBar={true}
                          newestOnTop={true}
                          autoClose={5000}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Form>
              )}
            </Formik>
          </MDBCol>          
        </MDBRow>
      </MDBContainer>
      </section>
    )
  }
}

export default eNewsletter
